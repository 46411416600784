import { Button, Checkbox, Form, Input, message } from "antd";
import React from "react";
import api from "@/common/api";
import styles from "../../../styles/Auth.module.less";
import { useRouter } from "next/router";
import { AuthContainer } from "@/common/components/authContent";
import utils from "@/common/utils";

export default function LoginForm() {
  const [form] = Form.useForm();
  const router = useRouter();
  const { next } = router.query;

  const validate = async () => {
    try {
      await form.validateFields();
      const username = form.getFieldValue("username");
      const password = form.getFieldValue("password");
      const {
        profile: { profiles },
      } = await api.login(username, password);
      console.log("!__!", next);
      if (typeof next === "string" && next) {
        router.push(next);
      } else {
        if (profiles.length === 1) {
          utils.changeProfile(profiles[0]);
          router.push("/dashboard/home");
        } else {
          router.push("/profile-switcher");
        }
      }
    } catch (ex) {
      message.error("Could not login. Check your credentials and try again.");
      console.error(ex);
      console.error("there is an error doing login");
    }
  };
  return (
    <AuthContainer>
      <Form
        form={form}
        name="basic"
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 320,
          outline: "none",
        }}
        layout={"vertical"}
      >
        <Form.Item
          label="Username"
          style={{ width: "100%" }}
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Password"
          style={{ width: "100%", marginBottom: 10 }}
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: 220,
          }}
        >
          <Button
            type="link"
            onClick={() => router.push("/auth/forgot-username")}
          >
            Forgot Username ?
          </Button>
          <Button
            type="link"
            onClick={() => router.push("/auth/forgot-password")}
          >
            Forgot Password ?
          </Button>
          <Button
            type="primary"
            className={styles.successBtn}
            htmlType="submit"
            onClick={validate}
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </AuthContainer>
  );
}
