import LoginForm from "@/modules/auth/LoginForm";

function Login() {
  return <LoginForm />;
}

Login.defaultProps = {
  hideNav: true,
  // enableHelp: true,
};

export default Login;
