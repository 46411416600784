import { Image } from "antd";
import Favicon from "../../../../public/favicon.png";
import styles from "../../../styles/Auth.module.less";

export const AuthContainer = (props: any) => {
  return (
    <div className={styles.authContainer}>
      <div className={styles.authData}>
        <div className={styles.stepsContent}>
          <div className={styles.content}>
            <div className={styles.image}>
              <div className={styles.logoImage}></div>
            </div>
            {props.children}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.phone}>
            {/* <i nz-icon nzType="phone" ></i> */}
            <span>816.775.0600</span>
          </div>
          <div className={styles.line}></div>
          <div className={styles.email}>
            {/* <i nz-icon nzType="mail" nzTheme="fill"></i> */}
            <a href="mailto:info@pineappleacademy.com">
              info@pineappleacademy.com
            </a>
          </div>
          <div className={styles.line}></div>
          <div className={styles.website}>
            <Image
              width={20}
              height={20}
              src={Favicon.src}
              alt="Pineapple Academy"
            />
            <a href="https://pineappleacademy.com">pineappleacademy.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};
